import { useParams } from "react-router-dom";
import { SiAparat } from "react-icons/si";
import { BsYoutube } from "react-icons/bs";
import { VscLoading } from "react-icons/vsc";
import { motion } from "framer-motion";
import axios from "axios";
import { useEffect, useState } from "react";

export default function DynamicPage() {
  let { path } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/ReqRouters.json");
        const findPath = response.data.filter((item) => {
          return item.path === path;
        });
        if (!findPath[0]) {
          return window.location.replace(404);
        }
        if (findPath[0].isDirect) {
          return window.location.replace(findPath[0].link[0]);
        }
        setData(findPath[0]);
        document.title = findPath[0].title;
        setLoading(false);
      } catch (error) {
        return window.location.replace(404);
      }
    };
    fetchData();
  }, []);
  if (loading) {
    return <VscLoading className="animate-spin w-12 h-12" />;
  }
  return (
    <>
      <p className="text-center">انتخاب کنید</p>
      <br />
      <div className="flex space-x-4 w-10/12">
        <a href={data.link[0]}>
          <motion.div
            whileHover={{ scale: 1.2 }}
            whileTap={{
              scale: 0.8,
            }}
            className="bg-gray-900 text-center pt-3 pb-2 px-8 rounded-xl flex flex-col justify-center bg-gradient-to-r hover:from-[#FA5252] hover:to-[#DD2476]"
          >
            <SiAparat size="2.8em" className="mt-1 mb-2" />
            <p>آپارات</p>
          </motion.div>
        </a>
        <a href={data.link[1]}>
          <motion.div
            whileHover={{ scale: 1.2 }}
            whileTap={{
              scale: 0.8,
            }}
            className="bg-gray-900 text-center py-3 px-7 rounded-xl flex flex-col justify-center bg-gradient-to-r hover:from-[#FA5252] hover:to-[#DD2476]"
          >
            <BsYoutube size="3.3em" />
            <p>یوتیوب</p>
          </motion.div>
        </a>
      </div>
    </>
  );
}
